import React from 'react';
import './Reasons.css'
import image1 from '/home/janewmwaura/personalprojectportfolio/FitClub-Starter/src/assets/image1.png'
import image2 from '/home/janewmwaura/personalprojectportfolio/FitClub-Starter/src/assets/image2.png'
import image3 from '/home/janewmwaura/personalprojectportfolio/FitClub-Starter/src/assets/image3.png'
import image4 from '/home/janewmwaura/personalprojectportfolio/FitClub-Starter/src/assets/image4.png'
import tick from '/home/janewmwaura/personalprojectportfolio/FitClub-Starter/src/assets/tick.png'
import nb from '/home/janewmwaura/personalprojectportfolio/FitClub-Starter/src/assets/nb.png'
import adidas from '/home/janewmwaura/personalprojectportfolio/FitClub-Starter/src/assets/adidas.png'
import nike from '/home/janewmwaura/personalprojectportfolio/FitClub-Starter/src/assets/nike.png'
const Reasons = () => {
    return (
        <div className="Reasons" id='reasons'>
            <div className="left-r">
                <img src={image1} alt ="" />
                <img src={image2} alt ="" />
                <img src={image3} alt ="" />
                <img src={image4} alt ="" />
            </div>
            <div className="right-r">
                <span>some reasons</span>
                <div>
                    <span className='stroke-text'>Why</span>
                    <span> choose us?</span>
                </div>

                <div className="details-r">
                    <div>
                        <img src={tick} alt=""></img>
                        <span>OVER 140+ EXPERT COACHES</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span>1 FREE PROGRAM FOR NEW MEMBERS</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span>RELIABLE PATNERS</span>
                    </div>
                </div>
                <span style={{
                    color : 'gray', 
                    fontweight: 'normal',
                }}>OUR PARTNERS</span>
                <div className="partners">
                    <img src={nb} alt="" />
                    <img src={adidas} alt="" />
                    <img src={nike} alt="" />
                </div>

            </div>

        </div>
    )
}
export default Reasons;